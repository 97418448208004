import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButtonPS = styled.button`
  font-family: 'Lora', serif;
  padding: 10px;
  border-radius: 2px;
  border: none;
  background-color: var(--secondary);
  font-weight: 600;
  color: var(--secondary-text);
  width: 124px;
  cursor: pointer;
  box-shadow: 0px 6px 2px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 5px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 2px 6px 0px -2px rgba(250, 250, 250, 0.3);
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  }
`;

export const StyledButtonPSClamed = styled.button`
  font-family: 'Lora', serif;
  padding: 10px;
  border-radius: 2px;
  border: none;
  background-color: rgb(120, 120, 120);
  font-weight: 600;
  color: var(--secondary-text);
  width: 124px;
  cursor: pointer;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [totalSupply, setTotalSupply] = useState(888);
  const [mintAmount3, setMintAmount3] = useState(1);
  const [DandyMintLimit, setDandyMintLimit] = useState(0);
  const [dandyClaimed, setDandyClaimed] = useState(0);
  const [claimingDandyNft, setClaimingDandyNft] = useState(false);
  const [feedbackDandy, setFeedbackDandy] = useState(``);
  const [dandySaleStart, setDandySaleStart] = useState(false);
  const [DandyProof, setDandyProof] = useState("");
  
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_VIP_COST: 0,
    WEI_AL_COST: 0,
    WEI_COST: 0,
    WEI_DANDY_COST: 0,
    DISPLAY_VIP_COST: 0,
    DISPLAY_AL_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_DANDY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkDandySaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .dandySaleStart()
      .call()
      .then((receipt) => {
        setDandySaleStart(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply()
      .call()
      .then((receipt) => {
        setTotalSupply(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkDandyMintLimit = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .dandyMintLimit()
      .call()
      .then((receipt) => {
        setDandyMintLimit(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkDandyClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .dandyClaimed(blockchain.account)
      .call()
      .then((receipt) => {
        setDandyClaimed(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const getDandy = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/dandy.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      //console.log('Dandy:hasheBefore/'+address);//確認したらコメントアウト
      //console.log('Dandy:hashed/'+hashedAddress);//確認したらコメントアウト
      //console.log("Dandy:proof/" + proof);//確認したらコメントアウト
      //console.log("Dandy:root/" + root);//確認したらコメントアウト
      setDandyProof(proof);
      dispatch(fetchData(blockchain.account));
    }
  };

  const claimDandyNFTs = () => {
    let cost = CONFIG.WEI_DANDY_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount3);
    let totalGasLimit = String(gasLimit * mintAmount3);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedbackDandy(`Minting...`);
    setClaimingDandyNft(true);
    blockchain.smartContract.methods
      .dandyMint(mintAmount3, DandyProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedbackDandy("失敗したので再度試してみてください");
        setClaimingDandyNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedbackDandy(
          `Mint success!!`
        );
        setClaimingDandyNft(false);
        checkDandyClaimed();
        checkTotalSupply();
        dispatch(fetchData(blockchain.account));
      });
  };



  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkDandyMintLimit();
    checkDandyClaimed();
    checkDandySaleStart();
    getDandy();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkDandyMintLimit();
    checkDandyClaimed();
    checkDandySaleStart();
    getDandy();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
        style={{backgroundSize: "cover",backgroundRepeat: "no-repeat"}}
      >
      <s.Container
        flex={1}
        ai={"center"}
        style={{background: "rgba(0, 0, 0, 0.8)"}}
      >
      <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 26, textAlign: "left", color: "#fff", backgroundColor: "rgba(0, 0, 0)"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledLink style={{ color: "#fff", margin: "0px 0px 0px 24px" }} href="https://exodus-mintsite.com">
            <img style={{ width: "82px", marginTop: "12px", marginRight: "12px" }} src="/config/images/logo_menu.png" alt="EXODUS LOGO"></img>
          </StyledLink>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: "24px" }}>
            <a href="https://twitter.com/EXODUS_FFW" target="_blank" rel="noopener" style={{ marginRight: "12px" }}>
              <img style={{ width: "26px",marginTop:"11px" }} src="/config/images/tw.png" alt="Twitter Icon"></img>
            </a>
            <a href="https://discord.gg/YGWbAKaaZX" target="_blank" rel="noopener" style={{ marginRight: "12px" }}>
              <img style={{ width: "26px",marginTop:"11px" }} src="/config/images/dc.png" alt="Discord Icon"></img>
            </a>
            <a href="https://opensea.io/collection/exodus-1st" target="_blank" rel="noopener">
              <img style={{ width: "26px",marginTop:"11px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
            </a>
          </div>
        </div>
      </s.TextDescription>

      

      <s.Container
        flex={1}
        ai={"center"}
      >

        <s.Container ai={"center"} jc={"center"} fd={"row"} style={{flexWrap: "wrap"}}>
          <img className="logo"
            style={{maxWidth: "70%", width: "320px", marginTop: "80px"}}
            src="/config/images/logo.png"
            alt="Logo"
          />
        </s.Container>
        <ResponsiveWrapper flex={1} style={{ padding: "0px 20px 10px 20px", maxWidth:"660px"}}>          
          <s.Container
            flex={2}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0)",
              padding: 5,
              borderRadius: 24,
              border: "none",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0)"
            }}
          >

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 36,
                color: "var(--accent-text)",
                marginBottom:"8px"
              }}
            >
              {totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>

              
            <s.TextTitle
                  style={{fontSize:"32px",textAlign: "center", color: "var(--accent-text)"}}
                >- 1st Generative -
            </s.TextTitle>
            <s.TextDescription
                  style={{textAlign: "center", color: "var(--accent-text)"}}
                >The 1st Generative Art sale has ended. 
            </s.TextDescription>
            <s.TextDescription
                  style={{textAlign: "center", color: "var(--accent-text)"}}
                > Please keep an eye out for our future activities.
            </s.TextDescription>
            
            <s.TextTitle
                  style={{fontSize:"28px",textAlign: "center", color: "var(--accent-text)"}}
            >Thank you!!
            </s.TextTitle>
            {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
              <s.SpacerSmall/>
                
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  <img style={{ width: "42px",marginTop:"4px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButtonPS
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButtonPS>
                    <s.SpacerSmall />
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedbackDandy}
                    </s.TextDescription>
                    {/* Dandyここから */}
                    {DandyProof != "" && dandySaleStart == true ? (//DandyAL確認
                        <>
                        {dandyClaimed > DandyMintLimit - 1 ? (
                          <>
                          <s.SpacerMedium />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>

                          <StyledButtonPSClamed
                            disabled={1}//claimingVipNftがtrueなら disabledを表示させる。＝クリックできない
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {"Minted"}
                          </StyledButtonPSClamed>
                          </s.Container>
                          </>
                        ) : (
                          <>
                          <s.SpacerSmall />
                            
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButtonPS
                                disabled={claimingDandyNft ? 1 : 0}//claimingDandyNftがtrueなら disabledを表示させる。＝クリックできない
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimDandyNFTs();
                                  getData();
                                }}
                              >
                              {claimingDandyNft ? "MINTING" : "MINT"}
                              </StyledButtonPS>
                            </s.Container>
                            {dandyClaimed > 0 ? (
                                <>
                                </>                              
                            ):(
                            <></>
                            )}
                          </>
                        )}
                        </>
                      ) : (
                        <>
                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        
                        </s.Container>
                        
                        </>
                      )}
                      {/* Dandyここまで */}
                  </> 
                )}
              </>
            )}
            
            <s.SpacerLarge/>
            <s.TextSubTitle
                style={{
                textAlign: "center",
                color: "var(--accent-text)",
                marginBottom: "1px",
                fontSize:"22px"
                }}
            >
              Collaborator
            </s.TextSubTitle>
            <s.TextDescription
                style={{
                textAlign: "center",
                color: "var(--accent-text)",
                }}
            >
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Blue²</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>がいくん</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>いなみ かずや</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>けだま</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>コユキ</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Lucky</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>まむあ</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>MARDY COLOR</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>MEAS</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>METANO</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Nekohige</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>NIKO24</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>NOAH</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>NOAK</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>おかやーまん</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>うちなんちゅ</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>わらいごえ</span>
            </s.TextDescription>
            <s.SpacerLarge/>
            <s.TextSubTitle
                style={{
                textAlign: "center",
                color: "var(--accent-text)",
                marginBottom: "0px",
                fontSize:"22px",
                verticalAlign: "bottom"
                }}
            >
              Special thanks
            </s.TextSubTitle>
            <s.TextDescription
                style={{
                textAlign: "center",
                color: "var(--accent-text)",
                verticalAlign: "top"
                }}
            >
              (AMA & Giveaway etc...)
            </s.TextDescription>
            <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>マコト</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>チャウダー</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ミニ</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>BUN</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>iconmania</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ラッシャー</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>DOZAN11</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ｵｼﾞﾁｬﾝ</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Big Hat Monkey</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Bプルート</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ぴもの</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ROKU</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ブドウちゃん</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ヒオキン</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ONIちゃん</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>kit</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ZUN</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>トリタツ</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>yuta</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>なりぷと</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ONIマPROJECT</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Monkeys</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>JUNKeeeeS</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>W3S</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>ごじゃ会</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>上が緑で下が黄色のコミュニティ</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Sound Desert</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Covered People</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>おっさんだお</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Cute Pigs</span>
              <span style={{ display: "inline-block" ,margin:"0px 4px 0px 4px"}}>Emily Knights</span>
            </s.TextDescription>
            <s.TextDescription
                style={{
                textAlign: "center",
                color: "var(--accent-text)",
                }}
            >
              All homies
            </s.TextDescription>
            </s.Container>
        </ResponsiveWrapper>
        <s.SpacerLarge/>
        </s.Container>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}
export default App;